import { SimpleItem } from 'types';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type SizesFilterProps = {
  sizes: SimpleItem[];
  value: number[];
  onChange: (newValue: number[]) => void;
};

export const SizesFilter = ({ sizes, value, onChange }: SizesFilterProps) => {
  const handleSelect = (size: string) => {
    const index = value.indexOf(size);

    if (index < 0) {
      onChange([...value, size]);
    } else {
      const newValue = value.slice();
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  return (
    <div className={styles.sizesFilter}>
      {!sizes?.length
        ? 'All Sizes'
        : sizes.map((size) => (
            <div
              key={size.id}
              className={cx(styles.sizesFilterItem, {
                [styles.active]: value.indexOf(size.title) >= 0,
              })}
              onClick={() => handleSelect(size.title)}
            >
              {size.title}
            </div>
          ))}
    </div>
  );
};
